export const environment = {
  production: false,
  environmentName: 'staging',
  tawkUrl: 'https://embed.tawk.to/602a48a0918aa261273ef022/1euiincuv',
  oAuth2Endpoint: 'https://2fafe.staging.gpi-pulse.com/',
  gatewayApiEndpoint: 'https://pulsebe.staging.gpi-pulse.com/api',
  gatewayApiEndpointHorizon: 'https://horizonbe.staging.gpi-pulse.com/api',
  gatewayApiEndpointImportExport: 'http://10.4.8.112',
  b2cPolicies: {
    clientId: '7b1b1f16-e73c-4731-afec-1c201a862cb3',
    authorityDomain: 'heliosprod.b2clogin.com',
    names: {
      signUpSignIn: 'B2C_1_sisu',
      forgotPassword: 'B2C_1_passwordreset',
      editProfile: 'B2C_1_editprofile'
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://heliosprod.b2clogin.com/heliosprod.onmicrosoft.com/B2C_1_signin',
      },
      forgotPassword: {
        authority: 'https://heliosprod.b2clogin.com/heliosprod.onmicrosoft.com/B2C_1_passwordreset',
      },
      editProfile: {
        authority: 'https://heliosprod.b2clogin.com/heliosprod.onmicrosoft.com/B2C_1_editprofile'
      }
    }
  },
  apiConfig: {
    scopes: ['https://heliosprod.onmicrosoft.com/989ab163-0313-42da-9a01-21d5f671eb51/datastore.query'],
    uri: 'https://pulsebe.staging.gpi-pulse.com/api/*'
  },
  horizonApiConfig: {
    scopes: ['https://heliosprod.onmicrosoft.com/989ab163-0313-42da-9a01-21d5f671eb51/datastore.query'],
    uri: 'https://horizonbe.staging.gpi-pulse.com/api/*'
  },
  appInsights: {
    instrumentationKey: 'dc18da9b-3b59-405f-9051-d5e9ce7c3db7',
    dependencyLogging: 'true',
    pageViewLogging: 'true',
  },
  googleAnalytics: {
    key: 'G-030LZ2GE44'
  },
  builderIo: {
    key: '3c0d498f824c4afdb8210bfcdf871064'
  }
};
